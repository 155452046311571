:root {
  --c-sole24ore-default: #f5e5d5;
  --c-sole24ore-light: #fff5ec;
  --c-sole24ore-dark: #e6d5c4;
  --c-sole24ore-darkblue: #071c3b;
  --c-sole24ore-darkred: #a9271c;
  --c-sole24ore-darkgray: #252525;
  --c-sole24ore-border: rgba(15, 15, 15, 0.25);
  --c-sole24ore-links: #003c7f;
  --c-sole24ore-links-hover: rgba(0, 60, 127, 0.7);
}

body {
   background-color: var(--c-sole24ore-default);
}